import {Observable, Observer} from 'rxjs';

import {AuthenticateParams, AuthenticateRes, LoginType} from '../../shared/users/user.class';
import {ImpError} from '../imp-error/imp-error.class';
import {StandardJSONResponse} from '../../shared/tools/AS.types';

export class AuthenticationService {
    /**
     * Authenticates a user
     * @param loginEmail - User email address
     * @param loginPassword - User password
     * @param stayAuthed - Should session be non-transient
     * @param loginType - Login type (affects response)
     * @param reauthenticate - If re-auth workflow should be used
     */
    public authenticateUser(
        loginEmail: string,
        loginPassword: string,
        stayAuthed: boolean,
        loginType: LoginType,
        reauthenticate: boolean,
    ): Observable<AuthenticateRes> {
        // WARNING: Converting this to a promise will break JavaScript on the consuming page for a production build in the current ES5 supported build platform (ICOM-11699)
        return new Observable((observer: Observer<any>) => {
            // Build params
            const logonParams = {
                login: loginEmail,
                loginType,
                Password: loginPassword,
                reauthenticate,
            } as AuthenticateParams;

            // Add stayAuthed if requested
            if (stayAuthed === true) {
                logonParams.stayauthed = `on`;
            }

            // Post logon info
            // eslint-disable-next-line
            $.ajax({
                type: `POST`,
                url: `/logon`,
                data: logonParams,
            })
                .done((authenticateUserRes) => {
                    observer.next(authenticateUserRes);
                    observer.complete();
                })
                .fail((authenticateUserErr) => {
                    observer.error(new ImpError(authenticateUserErr));
                    observer.complete();
                });
        });
    }

    /**
     * Change the user's password
     * @param passwordResetToken - Authorization token
     * @param newPassword - New password
     */
    public changePassword(passwordResetToken: string, newPassword: string): Promise<any> {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            $.ajax({
                type: `POST`,
                url: `/api/authentication/changePassword`,
                data: {newPassword, passwordResetToken},
            })
                .done((createAccountRes) => {
                    resolve(createAccountRes);
                })
                .fail((createAccountErr) => {
                    reject(new ImpError(createAccountErr));
                });
        });
    }

    /**
     * Change the user's password
     * @param existingPassword - Authorization token
     * @param newPassword - New password
     * @param captcha - captcha token
     */
    public changeExistingPassword(existingPassword: string, newPassword: string, captcha: string): Promise<StandardJSONResponse> {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            $.ajax({
                type: `POST`,
                url: `/api/authentication/changeExistingPassword`,
                data: {newPassword, existingPassword, 'g-recaptcha-response': captcha},
            })
                .done((changePassRes) => {
                    resolve(changePassRes);
                })
                .fail((changePassErr) => {
                    reject(new ImpError(changePassErr));
                });
        });
    }
}
